<template>
	<div>
		<div id="dailyPassengerFlow" v-show="isShow">
			<jy-query ref="form" :model="form">
				<jy-query-item prop="orgName" label="机构:">
					<div @click="selectInstitutions">
						<el-input placeholder="请选择" v-model="form.orgName"></el-input>
					</div>
				</jy-query-item>
				<!-- <jy-query-item prop="xianlu" label="车牌号:">
                    <el-input placeholder="请输入" v-model="form.xianlu"></el-input>
                </jy-query-item>
                <jy-query-item prop="xianlu" label="司机:">
                    <el-input placeholder="请输入" v-model="form.xianlu"></el-input>
                </jy-query-item>-->
				<jy-query-item label="查询日期:" prop="time">
					<el-date-picker
						v-model="form.time"
						value-format="yyyy-MM"
						type="monthrange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						:unlink-panels="true"
					></el-date-picker>
				</jy-query-item>
				<template slot="search">
					<el-button type="primary" @click="oncheck">查询</el-button>
					<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
					<!-- <el-row> -->
					<!-- <el-button type="primary">导出</el-button> -->
					<!-- </el-row> -->
				</template>
			</jy-query>
			<div class="echart_box">
				<div class="num_box">
					<numCharts :list="numList"></numCharts>
				</div>
				<div class="total_box">
					<totalCharts :list="totalList"></totalCharts>
				</div>
			</div>
			<div>
				<jy-table max-height="595" :data="dataList" @cell-click="inDetail">
					<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
					<jy-table-column prop="orgName" label="机构"></jy-table-column>
					<jy-table-column prop="strandNumSum" label="滞站次数"></jy-table-column>
					<jy-table-column prop="skipNumSum" label="越战次数"></jy-table-column>
					<jy-table-column prop="totalSum" label="总次数"></jy-table-column>
				</jy-table>
				<!-- <div class="jy_pagination">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-sizes="global.pageSizesList" :page-size="pageSize" :layout="global.pageLayout" :total="total">
                    </el-pagination>
                </div>-->
			</div>
		</div>
		<div v-show="!isShow">
			<detail @update="update" ref="detail"></detail>
		</div>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			url="/system/org/queryOrgTree"
			title="选择机构"
		></institutions-tree>
	</div>
</template>
<script>
import numCharts from "@/components/pages/admin/statisticalAnalysis/business/strandedAcrossAnalysis/numCharts";
import totalCharts from "@/components/pages/admin/statisticalAnalysis/business/strandedAcrossAnalysis/totalCharts";
import detail from "@/components/pages/admin/statisticalAnalysis/business/strandedAcrossAnalysis/detail";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			// 详情与否
			isShow: true,

			form: {
				orgName: "",
				orgId: "",
				time: [],
				beginDate: "",
				endDate: ""
			},
			// pageSize: 10,
			// total: 100,
			// pageIndex: 1,
			dataList: [
				{
					orgName: "",
					strandNumSum: "",
					skipNumSum: "",
					totalSum: ""
				}
			],

			// 图表
			numList: [],
			totalList: []
		};
	},
	created() {
		this.getList();
	},
	components: {
		numCharts,
		totalCharts,
		detail,
		institutionsTree
	},
	methods: {
		getList() {
			let option = {
				...this.form
				// pageIndex: this.pageIndex,
				// pageSize: this.pageSize
			};
			if (option.time) {
				option.beginDate = option.time[0];
				option.endDate = option.time[1];
			}
			let url = "/statstrandskipsitedaily/queryStrandSkipInfoByOrgId";
			// console.log(option)
			this.$http.post(url, option).then(res => {
				if (res.retain == "1") {
					this.dataList = [];
					this.$message({
						message: res.error,
						type: "warning"
					});
				} else {
					this.dataList = res.detail.list;
					this.numList = this.dataList;
					this.totalList = res.detail.orgTotalNumComparisonResult;
				}
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.form.orgId = "";
			this.form.beginDate = "";
			this.form.endDate = "";
			this.oncheck();
		},
		// 返回上一级
		update() {
			this.isShow = true;
		},
		// 进入详情
		inDetail(row) {
			this.isShow = false;
			this.$refs.detail.init(row);
			console.log(row);
		},
		// 选择机构树
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 上级机构查询
		addInstitutions(data) {
			// console.log(data)
			this.form.orgId = data.orgId;
			this.form.orgName = data.name;
		}
		// 分页
		// handleSizeChange(val) {
		//     this.pageSize = val
		//     this.getList()
		// },
		// handleCurrentChange(val) {
		//     this.pageIndex = val
		//     this.getList()
		// }
	}
};
</script>
<style lang="scss" scoped="dailyPassengerFlow">
#dailyPassengerFlow {
	.echart_box {
		display: flex;

		.num_box {
			width: 30%;
		}

		.total_box {
			width: 70%;
		}
	}
}
</style>
